import {
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaDiscord,
  FaSpotify,
} from "react-icons/fa6";
import { FaReact, FaHtml5, FaCss3 } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { SiTailwindcss, SiDaisyui } from "react-icons/si";

export const Navbar_List = [
  {
    menu: "Home",
    path: "/",
  },
  {
    menu: "About",
    path: "/about",
  },
  {
    menu: "Project",
    path: "/project",
  },
  {
    menu: "Contact",
    path: "/contact",
  },
];

export const Footer_Menu = [
  {
    title: "Our Service",
    menu: [
      {
        list: "Home",
        path: "/",
      },
      {
        list: "My Work",
        path: "/project",
      },
      {
        list: "About me",
        path: "/about",
      },
    ],
  },
  {
    title: "Company",
    menu: [
      {
        list: "Terms of Use",
        path: "/contact",
      },
      {
        list: "Contact Us",
        path: "/contact",
      },
    ],
  },
];

export const Footer_Logo = [
  {
    icon: <FaInstagram  />,
    link: "https://www.instagram.com/nbntang/",
  },
  {
    icon: <FaTwitter />,
    link: "https://twitter.com/nbntangg",
  },
  {
    icon: <FaLinkedin />,
    link: "https://www.linkedin.com/in/nur-bintang-8784b028a/",
  },
  {
    icon: <FaDiscord />,
    link: "https://discordapp.com/users/577037287758495744",
  },
  {
    icon: <FaSpotify />,
    link: "https://open.spotify.com/user/31f7tukeku3oqg7us4dkwr4qq5wa?si=09785d8f92d44c75",
  },
];

export const About_Skills = [
  {
    logo: <FaHtml5 />,
    aos: "fade-up",
    easing: "linear",
    duration: "200",
    colorText: "#b91c1c",
  },
  {
    logo: <FaCss3 />,
    aos: "fade-up",
    easing: "linear",
    duration: "400",
    colorText: "#2563eb",
  },
  {
    logo: <IoLogoJavascript />,
    aos: "fade-up",
    easing: "linear",
    duration: "600",
    colorText: "#facc15",
  },
  {
    logo: <SiTailwindcss />,
    aos: "fade-up",
    easing: "linear",
    duration: "800",
    colorText: "#06b6d4",
  },
  {
    logo: <FaReact />,
    aos: "fade-up",
    easing: "linear",
    duration: "1000",
    colorText: "#0ea5e9",
  },
];

export const Project_List = [
  {
    logo: "assets/img/gym.png",
    title: "Gym-Shop Website",
    path: "https://gym-shop-react-js.vercel.app/",
    duration: "500",
    icons: [
      {
        icon: <SiTailwindcss />,
      },
      {
        icon: <FaReact />,
      },
    ],
  },
  {
    logo: "assets/img/cafe.png",
    title: "Le Cafée with Grid Layouting Website",
    path: "/*",
    duration: "700",
    icons: [
      {
        icon: <FaHtml5 />,
      },
      {
        icon: <FaCss3 />,
      },
    ],
  },
  {
    logo: "assets/img/todolist.png",
    title: "To-Do-List App",
    path: "/*",
    duration: "900",
    icons: [
      {
        icon: <FaHtml5 />,
      },
      {
        icon: <FaCss3 />,
      },
      {
        icon: <IoLogoJavascript />,
      },
    ],
  },
  {
    logo: "assets/img/travelojan.png",
    title: "Nature Travel/ Hiking Website",
    path: "/*",
    duration: "1200",
    icons: [
      {
        icon: <FaHtml5 />,
      },
      {
        icon: <SiTailwindcss />,
      },
    ],
  },
  {
    logo: "assets/img/porto.png",
    title: "My Old Portofolio Website",
    path: "",
    duration: "1500",
    icons: [
      {
        icon: <FaHtml5 />,
      },
      {
        icon: <IoLogoJavascript />,
      },
      {
        icon: <SiTailwindcss />,
      },
    ],
  },
  {
    logo: "assets/img/travel.png",
    title: "Traveling Website",
    path: "",
    duration: "1800",
    icons: [
      {
        icon: <FaHtml5 />,
      },
      {
        icon: <SiTailwindcss />,
      },
      {
        icon: <SiDaisyui />,
      },
    ],
  },
];


export const sertifList = [
  {
    image: 'assets/sertif/sertif_js.jpg',
    title: "Basic Javascript Programming Language",
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem culpa architecto illum iste, ullam distinctio, soluta dicta quasi quaerat eos ut eligendi est ad, dolorem quos magni accusantium. Blanditiis ducimus ullam accusantium? Lorem',
    company: 'PT Dicoding Indonesia'
  },
  {
    image: 'assets/sertif/sertif_web.jpg',
    title: "Basic Web Programming Language",
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem culpa architecto illum iste, ullam distinctio, soluta dicta quasi quaerat eos ut eligendi est ad, dolorem quos magni accusantium. Blanditiis ducimus ullam accusantium? Lorem',
    company: 'PT Dicoding Indonesia'
  },
  {
    image: 'assets/sertif/sertif_htmlcss.jpg',
    title: "Basic HTML & CSS Language",
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem culpa architecto illum iste, ullam distinctio, soluta dicta quasi quaerat eos ut eligendi est ad, dolorem quos magni accusantium. Blanditiis ducimus ullam accusantium? Lorem',
    company: 'PT Codepolitan'
  },
] 
