import React from "react";
import Button from "../Elements/Button/Button";
import Label from "../Elements/Form/InputLabel";
import { FaTelegramPlane } from "react-icons/fa";
import emailjs from '@emailjs/browser';
import toast from "react-hot-toast";

export const ContactForm = () => {
  const formRef = React.useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_zsk6u87', 'template_geapk8d', formRef.current, {
        publicKey: '2ZyGqJyOPk_DmDLwY',
      })
      .then(
        () => {
          toast.custom((t) => {
            return <h1 className={`${t.visible ? "bg-opacity-100 bg-soft-black-0 dark:bg-soft-krem-0" : "opacity-0"} flex font-questrial items-center justify-center rounded-md duration-300 text-sm p-3 gap-x-4  max-w-md  text-soft-gray-0 dark:text-soft-black-0`}>
              <FaTelegramPlane
                className={window.document.documentElement.classList.contains("dark") ? "text-soft-black-0" : "text-soft-krem-0"}
              />
              Thank you for your message, the creator will see your message soon {":)"}</h1>;
          }, {
            duration: 2500
          });
        },
        (error) => {
          toast.error("somethinng went wrong..., might try that again later :(");
        },
      );

    e.target.reset();
  };


  return (
    <div className="min-w-0 mt-4 block">
      <div>
        <h1 className="text-3xl font-bold font-oswald ">
          Let's Connect Together!
        </h1>
        <form
          ref={formRef}
          onSubmit={sendEmail}
          className="flex  sm:w-96 w-[320px]  min-w-0 gap-y-1 [&>*]:outline-none  flex-col mt-4"
        >
          <Label name={'user_name'} text={'text'} placeholder={'Name'}
            className={'rounded-tl-2xl'} />
          <Label name={'user_email'} text={'email'}
            placeholder={'Email'} />
          <textarea
            name="message"
            className="border  p-2 max-h-40 placeholder:opacity-50 shadow rounded-br-3xl rounded placeholder:text-soft-black-0 lg:max-h-80 bg-soft-gray-0 border-soft-black-0 text-soft-black-0"
            id="message"
            cols="30"
            rows="10"
            placeholder="Type your message"
            required
          ></textarea>
          <div className="mt-2">
            <Button type={'submit'} value={'send'} className="rounded-md px-3 py-2 shadow border hover:opacity-80 border-soft-black-0   bg-soft-black-0 text-soft-yellow-0 dark:border-soft-yellow-0 hover:dark:text-soft-black-0 dark:text-soft-gray-0 hover:dark:bg-soft-krem-0 font-bold w-40 " >
              <span className="flex items-center justify-between">
                <h1>
                  Send Message
                </h1>
                <FaTelegramPlane />
              </span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};