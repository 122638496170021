import React from "react";
import { About_Skills } from "../components/Elements/data/List";
import SkillsLogo from "../components/Elements/LogoSkills/SkillsLogo";
import TimeHooks from "../hooks/TimeHooks";
import Code from "../components/UI/Code";
import Sertificate from "../components/UI/Sertificate";

export default function About() {
  const { myAge } = TimeHooks();


  return (
    <>
      <section className="w-full h-screen flex dark:bg-soft-black-0 bg-soft-krem-0 justify-center sm:items-center items-start ">
        <div className="flex justify-center items-center">
          <div className="mt-3 gap-4 flex md:flex-row-reverse flex-col ">
            <div className=" sm:inline flex justify-center">
              <div className="rounded w-80 grid place-items-center mr-4 ">
                <Code aos={"fade-down"} duration={"1000"} />
              </div>
            </div>
            <div
              className=" text-left sm:text-right max-w-[500px] mx-2 "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className=" font-bold font-oswald dark:text-soft-gray-0 text-soft-black-0 md:text-7xl sm:text-5xl text-3xl my-3 ">
                WHO AM I?
              </h1>
              <div className="text-justify max-w-96">
                <article className="w-full max-w-xs sm:max-w-sm text-soft-black-0 dark:text-soft-gray-0 min-w-0 font-questrial md:text-lg text-xs sm:text-sm  leading-6">
                  I am Nur Bintang Hidayat, a {myAge}-year-old college student
                  from Gunadarma University and web developer from Indonesia. I
                  lead a life enriched with various interests including coding,
                  working out, and music. My skills encompass Javascript,
                  ReactJs, TailwindCSS, and more. My life journey is reflected
                  in my determination to become a great developer, inspire
                  others, and continuously revolutionize myself.
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="h-96 bg-cover relative border-soft-black-0 flex justify-center bg-center after:content-[''] after:inset-0 after:absolute dark:after:bg-[#000000cc]   text-soft-gray-0 font-dancing-script items-center text-3xl bg-fixed "
        style={{ backgroundImage: "url(assets/lucifer.jpg" }}
      >
        <div className="flex z-50 flex-col m-3 ">
          <div data-aos="fade" data-aos-duration="1000">
            <h1 className="sm:text-5xl text-center text-3xl font-bold leading-10">
              “For a{" "}
              <span className="bg-black px-2 dark:bg-white dark:text-black">
                {" "}
                man
              </span>{" "}
              to{" "}
              <span className="bg-black dark:bg-white px-2 dark:text-black">
                conquer
              </span>{" "}
              himself is the first and noblest of all{" "}
              <span className="bg-black px-2 dark:bg-white dark:text-black">
                {" "}
                victories.
              </span>
              ”
            </h1>
          </div>
          <div className="" data-aos="fade" data-aos-duration="2000">
            <p className="text-center sm:mr-7 mt-5 sm:text-right ">-Plato.</p>
          </div>
        </div>
      </section>
      <section className="w-full bg-soft-krem-0 dark:bg-soft-black-0  h-[500px] text-soft-black-0 dark:text-soft-gray-0 font-bold flex items-center justify-center flex-col ">
        <div
          className="mb-3 space-y-5"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="text-4xl text-center sm:text-6xl font-bold font-oswald">
            SKILLS THAT I LEARNED
          </h1>
          <div className="flex  justify-center w-full">
            <div className="flex max-w-full text-5xl min-w-0 justify-around px-3 gap-4 flex-wrap">
              {About_Skills.map(({ logo, aos, duration, colorText }) => {
                return (
                  <>
                    <SkillsLogo
                      logo={logo}
                      aos={aos}
                      duration={duration}
                      colorText={colorText}
                      key={logo}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-soft-krem-0  dark:bg-soft-black-0  ">
        <div className="flex justify-center pb-8">
          <div className="mx-3 w-full  max-w-7xl ">
            <h1 className="text-4xl md:text-6xl text-soft-black-0 dark:text-soft-krem-0 font-bold font-oswald">
              Sertificate
            </h1>
            <div className="flex flex-wrap mt-6 gap-9 justify-between  py-8  ">
              <Sertificate />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
