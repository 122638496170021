import React from "react";

const Label = ({
  name, 
  className, 
  text, 
  placeholder,
  onChange
}) => {
  return (
    <div className="flex flex-col  relative">
      <input
        name={name}
        placeholder={`Type your ${placeholder} here...`}
        type={text}
        onChange={onChange}   
        className={`${className} placeholder:opacity-50 p-4 border text-soft-black-0  bg-soft-gray-0 shadow  border-soft-black-0  placeholder:text-soft-black-0  `}
        required
      />
    </div>
  )
}

export default Label