import React from "react";
import FooterMenu from "../Fragments/Footer/FooterListed";
import TimeHooks from "../../hooks/TimeHooks";

const Footer = () => {
  const {time} = TimeHooks();
  return (
    <>
      <footer className="flex justify-center min-w-0  pt-3 w-full h-full  font-questrial bg-soft-black-0 text-soft-gray-0 dark:bg-soft-gray-0 dark:text-soft-black-0">
        <div className="flex flex-col w-full px-4 justify-between  items-center">
          <div className="flex flex-col md:flex-row flex-wrap justify-between w-full gap-y-4 my-3 max-w-6xl gap-x-3  ">
            <div className="max-w-sm" data-aos="fade" data-aos-duration="300">
              <h1 className="font-bold font-oswald text-lg sm:text-xl md:text-2xl">
                BXNTANG
              </h1>
              <article className="text-md sm:text-lg md:text-xl">
                "There are no limits to growth and learning." I stand prepared
                to realize my vision as a dependable fullstack developer.
              </article>
            </div>
            <FooterMenu />
          </div>
          <div className="border-t border-soft-krem-0 dark:border-soft-black-0 h-9 text-sm md:text-md mt-3 px-3 w-full">
            <p className="mt-2 flex justify-between">
              <span>©{`Build with React ${time.getFullYear()}.`}</span>
              <span>Bxntang.</span>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
