import React from "react";
import ContactSupport from "../components/UI/ContactSupp";
import { ContactForm } from "../components/UI/ContactForm";

export default function Contact() {
  return (
    <>
      <main className="w-full h-full  min-w-0 dark:bg-soft-black-0 font-questrial dark:text-soft-gray-0 bg-soft-krem-0  text-soft-black-0 py-10">
        <div className="flex items-center h-full  md:h-screen ">
          <section className="flex  w-full justify-evenly items-center flex-wrap px-3  py-6 ">
            <ContactSupport  />
            <ContactForm />
          </section>
        </div>
      </main>
    </>
  );
}
