import React from "react";
import { ClockLoader } from "react-spinners";
import LoadingHooks from "../../../hooks/LoadingHooks";
const Loading = ({ className, children, aos, duration }) => {
  const loading = LoadingHooks();
  return (
    <>
      {loading ? (
        <div
          className={`${className}
    grid place-items-center w-full dark:bg-soft-black-0 bg-soft-yellow-0`}
        >
          <div>
            <ClockLoader
              color="#61677A"
              size={150}
              speedMultiplier={3}
              data-aos={aos}
              data-aos-duration={duration}
              className="animate-pulse"
            />
          </div>
        </div>
      ) : (
        <div className="w-auto h-auto">{children}</div>
      )}
    </>
  );
};
export default Loading;
