import React, { useState } from "react";
import useDarkMode from "../../../hooks/SetDarkMode";
import { DarkModeSwitch } from "react-toggle-dark-mode";

export default function Switcher({ className }) {
  const [colorTheme, setTheme] = useDarkMode();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <>
      <div>
        <DarkModeSwitch
          checked={darkSide}
          onChange={toggleDarkMode}
          className={className}
          moonColor="#FFFFF2"
          sunColor="#272829"
        />
      </div>
    </>
  );
}
