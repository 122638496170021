import React from "react";
import { Link } from "react-router-dom";
import { Navbar_List } from "../../Elements/data/List";
import Button from "../../Elements/Button/Button";
import { AsideToggle } from "../SetToggle/AsideToggle";
import useSidebar from "../../../hooks/useSidebar";

const Aside = React.forwardRef(() => {
  const { ref, open, setOpen } = useSidebar();
  return (
    <>
      <AsideToggle ref={ref} open={open} setOpen={setOpen} />
      <aside
        className={`absolute inset-0 flex z-20 sm:hidden h-screen -translate-y-[100%] dark:bg-soft-black-0 bg-soft-krem-0 ${
          open
            ? "translate-y-0 duration-500"
            : "-translate-y-[100%] duration-700"
        }`}
        onClick={() => setOpen(false)} // Close the aside when clicked outside
        ref={ref}
      >
        <ul className="flex flex-col h-screen  justify-center space-y-20 items-center w-full font-bold font-questrial">
          {Navbar_List.map(({ menu, path }) => (
            <Link to={path} key={menu}>
              <Button className="text-soft-black-0 font-oswald dark:text-soft-gray-0 text-6xl dark:hover:text-soft-yellow-0 hover:text-old-gray-0 shadow-none">
                {menu.toUpperCase()}
              </Button>
            </Link>
          ))}
        </ul>
      </aside>
    </>
  );
});

export default Aside;

