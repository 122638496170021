import React from "react";

const Button = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    onClick,
    type,
    value
  } = props;
  return (
    <>
      <button
      
        className={`${className} transition-all z-50 duration-300 active:bg-opacity-45 px-2 py-1 rounded-sm`}
        onClick={onClick}
        ref={ref}
        type={type}
        value={value}
      >
        <h1>{children}</h1>
      </button>
    </>
  );
});

export default Button;
