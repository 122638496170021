export default function Error() {
  return (
    <>
      <div className="h-screen w-full flex justify-center items-center dark:bg-soft-black-0 bg-soft-krem-0">
        <div className="flex items-center flex-col gap-5">
          <h1 className="text-7xl">
            <span className="dark:text-soft-gray-0 text-soft-black-0 font-oswald font-bold">
              404
            </span>
          </h1>
          <h1 className="text-3xl font-bold">
            <span className="dark:text-soft-gray-0 text-soft-black-0 font-oswald">
              Sorry, Page Not Found {":("}
            </span>
          </h1>
        </div>
      </div>
    </>
  );
}
