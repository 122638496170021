import Button from "../../Elements/Button/Button";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { FaBarsStaggered } from "react-icons/fa6";

export const AsideToggle = React.forwardRef(({ open, setOpen }, ref) => {
  return (
    <Button
      className={`sm:hidden relative z-50 flex justify-center dark:hover:text-soft-yellow-0 hover:text-old-gray-0 items-center hover:bg-none shadow-none duration-300 ${open ? "rotate-180 text-soft-black-0 dark:text-soft-gray-0 " : " text-soft-black-0 dark:text-soft-gray-0 rotate-0"}`}
      onClick={() => setOpen(!open)}
      ref={ref}
      type="button"
    >
      {open ? (
        <FaTimes className="text-2xl " />
      ) : (
        <FaBarsStaggered className="text-2xl" />
      )}
    </Button>
  );
});
