const SkillsLogo = ({ aos, duration, colorText, logo }) => {
  return (
    <div
      className={`border-2 md:w-40 md:h-40 sm:w-30 sm:h-30 grid place-items-center p-4 md:text-8xl md:p-5 mt-2 hover:bg-opacity-90 cursor-pointer border-black  shadow-md  bg-soft-black-0 dark:bg-soft-gray-0 rounded-sm`}
      data-aos={aos}
      data-aos-duration={duration}
      style={{
        color: colorText,
      }}
      key={duration}
    >
      {logo}
    </div>
  )
}
export default SkillsLogo