import Button from "../Button/Button";
export default function HireContact({aos}) {
  return (
    <div data-aos={aos} className="flex flex-col gap-y-2 w-full max-w-80 font-questrial dark:text-soft-black-0  text-soft-gray-0 bg-soft-black-0 dark:bg-soft-gray-0  p-3 dark:border-soft-yellow-0 border-soft-black-0 border rounded-md shadow-md justify-center">
      <div>
        <p className="font-bold text-xl">Contribute to my Mission!</p>
        <p>
          Curationist connects people to cultural knowledge from all over the
          world.
        </p>
      </div>
      <a
        href={"https://www.linkedin.com/in/nur-bintang-hidayat-8784b028a/"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Button className="p-2 py-2 rounded w-36 dark:text-soft-gray-0 bg-soft-gray-0 dark:bg-soft-black-0 shadow hover:text-soft-gray-0 hover:dark:text-soft-yellow-0  text-soft-black-0 hover:bg-old-gray-0 hover:dark:bg-old-gray-0 focus:outline-none  font-bold ">
          Hire Me!
        </Button>
      </a>
    </div>
  );
}
