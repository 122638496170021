import React from "react";
import { Project_List } from "../../components/Elements/data/List";
import { Link, useParams } from "react-router-dom";

const ProjectPages = () => {
  const { title } = useParams();
  const project = Project_List.find((project) => project.title === title);
  const { logo, path, icons, index, duration } = project;

  return (
    <>
      {project && (
        <div className="h-screen grid place-items-center text-xl font-bold ">
          <div>
            <h1>NANTI PINDAH KE NEXT JS YA GES YA :D</h1>
            <Link to={"/project"} className="underline">
              click disini
            </Link>
            {" "}
            buat balik lagih
          </div>
        </div>
      )}
      {/* {
    project && (
        <>
        
        <div>
            <h1>{project.title}</h1>
            {
               logo && <img src={logo} alt={project.title} />
            }
        
        </div>
        </>
    )
   } */}
    </>
  );
};

export default ProjectPages;
