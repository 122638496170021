import React from "react";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Project_List } from "../Elements/data/List";
import { Link} from "react-router-dom";

const ProjectUI = () => {
  
  return (
    <>
      {Project_List.map(({ logo, title, path, icons, index, duration }) => {
        return (
          <>
            <Link
            to={`/project/${title}` }
            
              key={index}
              data-aos={"fade"}
              data-aos-duration={duration}
            >
              <div className={`group max-w-sm md:max-w-4xl colums-1 md:columns-2 lg:columns-6 flex`}>
                <div className=" px-2 md:w-96 relative">
                  <img
                    src={logo}
                    alt="Project"
                    className="mb-4 blur-[2px] hover:blur-0 object-cover object-center rounded-md "
                  />
                  <div className=" group-hover:text-soft-krem-0 text-soft-black-0 dark:text-soft-gray-0 absolute flex items-center space-x-3 bottom-7  duration-300 transition-all  opacity-0 group-hover:opacity-100 right-6 ">
                    <p className=" font-oswald ">{title}</p>
                  <FaArrowUpRightFromSquare  />
                  </div>
                </div>
              </div>
            </Link>
          </>
        );
      })}
    </>
  );
};

export default ProjectUI;
