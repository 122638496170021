import TimeHooks from "../../hooks/TimeHooks";
import Button from "../Elements/Button/Button";
import { FaFileDownload } from "react-icons/fa";
// import LoadingHooks from "../../hooks/LoadingHooks";

const HomeUI = () => {
  const { myAge } = TimeHooks();
  // const loading = LoadingHooks();
  return (
    <>
      <div
        data-aos="fade-down" data-aos-duration="300"
      >
        <img
          className="rounded shadow-lg min-w-0 w-full sm:max-w-56 md:max-w-72 max-w-44"
          src="/assets/anime.jpg"
          alt="My Home Profile"
        />
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="1500"
        className="max-w-80 text-center md:text-left space-y-2"
      >
        <h1 className="font-oswald  font-bold text-4xl sm:text-7xl">
          HI, I'M BINTANG.
        </h1>
        <article className="font-para-quatt  text-lg ">
          A {myAge.toString()} years old Web Developer and a Student based in Depok. Currently
          focusing on my Fullstack journey.
        </article>
        <a href="/assets/CV/Nur-Bintang-Hidayat_CV.pdf" download={'Nur-Bintang-Hidayat_CV.pdf'}>
          <Button className={'border rounded-md mt-2 hover:dark:text-soft-black-0  shadow hover:text-soft-yellow-0 border-soft-black-0 dark:border-soft-krem-0  hover:bg-soft-black-0 hover:dark:bg-soft-krem-0 w-28'}>
            <span className="flex items-center font-inter justify-between w-full" >
              <h1 className="font-bold">
                Resume
              </h1>
              <FaFileDownload />
            </span>
          </Button>
        </a>
      </div>
    </>
  );
};

export default HomeUI;
