import React from "react";
import { Link } from "react-router-dom";
import Button from "../Elements/Button/Button";
import Switcher from "../Elements/Switcher/Switcher";
import Aside from "../Fragments/Sidebar/Aside";
import { Navbar_List } from "../Elements/data/List";
import { LiaStarHalfSolid } from "react-icons/lia";

export default function Navbar() {
  return (
    <header className="flex justify-center items-center z-50 bg-soft-krem-0 dark:bg-soft-black-0 min-w-0 px-4">
      <nav className="flex h-14 w-full min-w-0 text-soft-black-0 dark:text-soft-gray-0 items-center sm:justify-around justify-between mx-4">
        <div className="flex items-center sm:justify-around justify-between w-full">
          <div className="relative">
            <a href="/" className=" block  group drop-shadow ">
              <LiaStarHalfSolid className="text-4xl  absolute group-hover:text-old-gray-0 left-[5.2px] -translate-x-[22px] top-[5.1px]"/>
              <h1 className="no-underline font-bold  dark:hover:text-soft-yellow-0 group-hover:text-old-gray-0 transition-all duration-300 dark:text-gray-50 text-soft-black-0 font-oswald   text-3xl">
                BXNTANG
              </h1>
            </a>
          </div>
          <div className="flex items-center space-x-5">
            <div className="hidden sm:flex w-96 items-center justify-around px-3">
              {Navbar_List.map(({ menu, path }) => (
                <Link to={path} key={path}>
                  <Button className={' hover:text-old-gray-0 font-bold hover:dark:text-soft-yellow-0'}>{menu}</Button>
                </Link>
              ))}
            </div>
            <li className="list-none">
              <Switcher />
            </li>
            <li className="list-none">
              <Aside />
            </li>
          </div>
        </div>
      </nav>
    </header>
  );
}