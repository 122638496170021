import React from "react";
import TimeHooks from "../hooks/TimeHooks";
import { PropagateLoader } from "react-spinners";
import ProjectUI from '../components/UI/ProjectUI';
import LoadingHooks from "../hooks/LoadingHooks";

const Project = () => {
  const { time } = TimeHooks();
  const loading = LoadingHooks();

  const loadingScreenColor = window.document.documentElement.classList.contains("dark") ? "#fff" : "#000";
  return (
    <>
      <main className="dark:bg-soft-black-0  bg-soft-krem-0 text-soft-black-0  h-full py-7 w-full dark:text-soft-gray-0">
        <div className="w-full  min-w-0 flex space-y-5 items-center flex-col-reverse justify-center py-4">
          <div className="text-center max-w-[700px] min-w-0">
            <h1 className="md:text-5xl sm:text-3xl  text-2xl font-oswald font-bold py-6">{`2023 - ${time.getFullYear()}.`}</h1>
          </div>
          <div className="flex w-full max-w-6xl flex-wrap  justify-around items-center gap-7 px-4">

            {
              loading ?
                <div className="h-screen flex justify-center  w-full">
                  <PropagateLoader
                    color={loadingScreenColor}
                    size={20}
                    speedMultiplier={1.09}
                  />
                </div>
                :
                <ProjectUI />
            }

          </div>
        </div>
      </main>
    </>
  );
};

export default Project;
