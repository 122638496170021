import React from "react";
import { Footer_Logo, Footer_Menu } from "../../Elements/data/List";
import Button from "../../Elements/Button/Button";
const FooterMenu = () => {
  return (
    <>
      {Footer_Menu.map(({ title, menu }) => {
        return (
          <div
            data-aos="fade"
            data-aos-duration="500"
            className="flex flex-col "
            key={title}
          >
            <h1 className="text-md sm:text-lg font-bold" >
              {title}
            </h1>
            <ul className="space-y-2 text-sm  flex flex-col items-start justify-center">
              {menu.map(({ list, path }) => {
                return (
                  <>
                    <Button className="hover:underline text-md hover:text-soft-yellow-0 dark:hover:text-old-gray-0" key={list}>
                      <a href={path}>{list}</a>
                    </Button>
                  </>
                );
              })}
            </ul>
          </div>
        );
      })}
      <div className="space-y-1">
        <h1 className="font-bold text-lg">Follow Me</h1>
        <ul className=" ">
          {Footer_Logo.map(({ icon, link }) => {
            return (
              <>
                <Button className="text-4xl dark:bg-none dark:hover:text-old-gray-0 hover:text-soft-yellow-0" key={icon}>
                  <a href={link} target="_blank" rel="noreferrer noopener">{icon}</a>
                </Button>
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default FooterMenu;