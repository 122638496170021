import { useState, useEffect } from "react";
export default function TimeHooks() {
  const [time, setTime] = useState(new Date());
    const getAge = birth => Math.floor((new Date() - new Date(birth).getTime()) / 3.15576e+10)
    const myAge = getAge('2004-02-04');
  const intervalid = useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 6000);
    return () => {
      clearInterval(intervalid);
    };
  }, []);
  return {time, myAge};
}
