import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Navbar from "./components/UI/Navbar";
import Footer from "./components/UI/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Project from "./pages/Project";
import Error from "./pages/ErrorPage";
import Loading from "./components/Fragments/Skeleton/Loading";
import { Toaster } from "react-hot-toast";
import ProjectPages from "./pages/project/ProjectPages";

function App() {

  return (
    <>
      {
        <Loading className={'h-screen'} aos={"fade-up"} duration={'1500'}>
          <Router>
            <Toaster />
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/project" element={<Project />} />
              <Route path="/project/:title" element={<ProjectPages />}/>
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Error />}/>
              
            </Routes>
            <Footer />
          </Router>
        </Loading>
      }
    </>
  );
}

export default App;
