import React from "react";
import "aos/dist/aos.css";
import HomeUI from "../components/UI/HomeUI";
export default function Home() {
  return (
    <>
      <main className="w-full min-w-0 dark:bg-soft-black-0 bg-soft-krem-0 justify-center text-md flex dark:text-soft-gray-0 text-soft-black-0 h-screen items-center">
        <section className="flex flex-col md:flex-row items-center justify-center gap-6 px-5">
          <HomeUI />
        </section>
      </main>
    </>
  );
}
