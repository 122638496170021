import React from "react";
import { sertifList } from "../Elements/data/List";
import LoadingHooks from "../../hooks/LoadingHooks";


const Sertificate = () => {
  const loading = LoadingHooks();

  return (
    <>
      {sertifList.map(({ title, description, company, image }) => {
        return (
          <div 
            key={title}
          >
            {loading ? (
              <>
              
                <div className="space-y-1 mt-3">
                  <div className="w-72 sm:w-80  flex flex-col rounded bg-soft-gray-0 dark:bg-soft-krem-0 h-8 animate-pulse"></div>
                  <div className="flex flex-col gap-y-1">
                    <div className="w-80 bg-soft-gray-0 dark:bg-soft-krem-0 h-3 rounded animate-pulse"></div>
                    <div className="w-80 bg-soft-gray-0 dark:bg-soft-krem-0 h-3 rounded animate-pulse"></div>
                    <div className="w-80 bg-soft-gray-0 dark:bg-soft-krem-0 h-3 rounded animate-pulse"></div>
                    <div className="w-80 bg-soft-gray-0 dark:bg-soft-krem-0 h-3 rounded animate-pulse"></div>
                    <div className="w-72 bg-soft-gray-0 dark:bg-soft-krem-0 h-3 rounded animate-pulse"></div>
                  </div>
                  <div className="w-52  h-5 rounded bg-soft-gray-0 dark:bg-soft-krem-0 animate-pulse"></div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col gap-y-2">
                  {
                    image === "" ? (
                      <div>
                        this image is not available for now
                      </div>
                    ) : (
                      <img
                        src={image}
                        alt={title}
                        className="w-72 sm:w-80 h-32 object-cover rounded"
                      />
                    )
                  }
                  <h1 className="font-oswald flex text-soft-black-0 dark:text-soft-krem-0  text-2xl sm:text-3xl font-bold md:text-4xl">
                    {title.concat("s")}
                  </h1>

                  <article className="font-questrial text-soft-black-0 dark:text-soft-krem-0 max-w-lg text-wrap sm:text-lg text-xs">
                    {description}
                  </article>
                  <div className=" border-l-4 border-soft-black-0 dark:border-soft-yellow-0">
                    <h3 className=" text-sm sm:text-xl md:text-2xl text-soft-black-0 dark:text-soft-gray-0 font-semibold p-1 ">
                      {company}
                    </h3>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Sertificate;
