import React, { useEffect } from "react";
import Prism from "prismjs";
import TimeHooks from "../../../hooks/TimeHooks";
import "prismjs/themes/prism-tomorrow.css";

export default function CodeIdentity() {
  const { myAge } = TimeHooks();
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <>
      <pre className="whitespace-pre-wrap shadow-xl rounded-xl">
        <code className={`language-javascript text-gray-200   `}>
          {`const bxntang = {
name: "Nur Bintang Hidayat",
age: ${myAge},
country: "Indonesia",
jobs: ["College Student", "Web Developer"],
hobbies: ["Code", "Workout", "Music", ...others],
journey: [
    "My ambitious is to be great Developer!🧑‍💻",
    "Inspiring people!🔥",
    "Self Revolutioner!✊",
  ],
motto: (life) => {
  while (life === fails) {
    life.tryAgain();
    life.ambitions++;
  }},
};`}
        </code>
      </pre>
    </>
  )
}

