import HireContact from "../Elements/CardContact/HireContact";
export default function ContactSupport() {
  return (
    <div className="font-questria m-3  flex flex-col items-start justify-start  max-w-96 leading-7 gap-y-7">
      <div className="space-y-2">
        <h1 className="font-oswald sm:text-4xl md:text-6xl text-3xl font-bold">
          Your feedback helps us improve
        </h1>
        <div className="text-lg">
          <p>I am here to help you and we'd love to connect with you.</p>
        </div>
      </div>
      <HireContact aos={"fade-right"} />
    </div>
  );
}
