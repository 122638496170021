import CodeIdentity from '../Elements/Identity/CodeID'

export default function Code({ aos, duration }) {
  return (
    <div
      className="text-[10px] sm:text-xs w-80 sm:w-[auto]  rounded-md p-[2px]"
      data-aos={aos}
      data-aos-duration={duration}
      
    >
      <CodeIdentity />
    </div>
  );
}
